// -----------------------------------------------------------------------------
//   Bootstrap - Browser and device support
//   Internet Explorer 10 in Windows 8 and Windows Phone 8
//   http://getbootstrap.com/getting-started/#support-ie10-width
// -----------------------------------------------------------------------------

if ( navigator.userAgent.match(/IEMobile\/10\.0/) ) {
  var msViewportStyle = document.createElement('style')
  msViewportStyle.appendChild(
    document.createTextNode(
      '@-ms-viewport{width:auto!important}'
    )
  )
  document.querySelector('head').appendChild(msViewportStyle)
}

// -----------------------------------------------------------------------------
//   Bootstrap - Browser and device support
//   Android stock browser
//   http://getbootstrap.com/getting-started/#support-android-stock-browser
// -----------------------------------------------------------------------------

$(function() {
  var nua = navigator.userAgent,
      isAndroid = (nua.indexOf('Mozilla/5.0') > -1 && nua.indexOf('Android ') > -1 && nua.indexOf('AppleWebKit') > -1 && nua.indexOf('Chrome') === -1)
  if ( isAndroid ) $('select.form-control').removeClass('form-control').css('width', '100%')
})
